<template>
  <div>
    <form-wizard
      color="#3e74c7"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Frissítés"
      next-button-text="Tovább"
      next-button-icon="fa fa-arrow-right"
      back-button-text="Vissza"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
    >

      <!-- account datails tab -->
      <tab-content title="Alvállalkozó adatai">
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Alvállalkozó adatai
            </h5>
            <small class="text-muted">
              Kérlek add meg az információkat
            </small>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Cég név"
              label-for="company_name"
            >
              <b-form-input
                id="company_name"
                v-model="company_name"
                type="text"
                placeholder="Cég pontos neve"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Székhely"
              label-for="company_location"
            >
              <b-form-input
                id="company_location"
                v-model="company_location"
                type="text"
                placeholder="Cég székhelye"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Adószám"
              label-for="tax_number"
            >
              <b-form-input
                id="tax_number"
                v-model="tax_number"
                placeholder="Adószám"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Típusa"
              label-for="company_type"
            >
              <b-form-select
                id="company_type"
                v-model="company_type"
                :options="company_types"
                placeholder="Cég?"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Cégjegyzékszám"
              label-for="company_number"
            >
              <b-form-input
                id="company_number"
                v-model="company_number"
                placeholder="Cégjegyzékszám"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Kamarai regisztráció szám"
              label-for="chamber_number"
            >
              <b-form-input
                id="chamber_number"
                v-model="chamber_number"
                placeholder="Kamarai regisztráció szám"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Bankszámla szám"
              label-for="bank_account_number"
            >
              <b-form-input
                placeholder="Bankszámla szám"
                id="bank_account_number"
                v-model="bank_account_number"
                type="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Telefonszám"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="phone"
                placeholder="Telefonszám"
                type="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="E-mail cím"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                placeholder="E-mail címe"
                type="email"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="NÜJ száma"
              label-for="nuj_number"
            >
              <b-form-input
                id="nuj_number"
                v-model="nuj_number"
                placeholder="Napló Ügyfél Jelet száma"
                type="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Munkafolyamat amit végez"
              label-for="work"
            >
              <v-select
                v-model="work"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="text"
                :options="workOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-2">
            <h5>Képviselet rögzítése</h5>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Képviseli"
              label-for="represented"
            >
              <b-form-input
                id="represented"
                v-model="represented"
                placeholder="Képviseli"
                type="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group
              label="Titulus"
              label-for="role"
            >
              <v-select
                v-model="role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="text"
                :options="roleOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="1">
            <br class="d-none d-md-block">
            <button class="btn btn-primary w-100" @click="addToRoleGroup">
              <feather-icon
                icon="PlusCircleIcon"
                size="16"
              />
            </button>
          </b-col>
          <b-col md="12" class="mt-1 mt-md-0" v-if="role_group.length > 0">
            <span class="badge badge-primary mr-1 mt-1" v-for="(user, index) in role_group" :key="user">{{user.represented}} (
              <span v-for="role in user.roles" :key="role">{{role}},</span>)
              <span class="badge badge-light text-primary" @click="removeRoleGroup(index)"><feather-icon icon="MinusCircleIcon" size="16"/></span>
            </span>
          </b-col>
        </b-row>
        <b-row class="">
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0 mt-2">
              Felelős műszaki vezető
            </h5>
            <small class="text-muted">Add meg a felelős műszaki vezető adatait</small>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Neve"
              label-for="fmv_name"
            >
              <b-form-input
                id="fmv_name"
                v-model="fmv_name"
                placeholder="Neve"
                type="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Titulus"
              label-for="fmv_role"
            >
              <b-form-input
                id="fmv_role"
                v-model="fmv_role"
                placeholder="Titulus"
                type="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Telefonszám"
              label-for="fmv_phone"
            >
              <b-form-input
                id="fmv_phone"
                v-model="fmv_phone"
                placeholder="Telefonszám"
                type="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="E-mail cím"
              label-for="fmv_email"
            >
              <b-form-input
                id="fmv_email"
                v-model="fmv_email"
                placeholder="E-mail címe"
                type="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Azonosító"
              label-for="fmv_number"
            >
              <b-form-input
                id="fmv_number"
                v-model="fmv_number"
                placeholder="Azonosító"
                type="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="NÜJ száma"
              label-for="fmv_nuj_number"
            >
              <b-form-input
                id="fmv_nuj_number"
                v-model="fmv_nuj_number"
                placeholder="Napló Ügyfél Jelet száma"
                type="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>

  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    VueAutosuggest,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      company_name: '',
      company_location: '',
      tax_number: '',
      company_number: '',
      chamber_number: '',
      company_type: '',
      bank_account_number: '',
      represented: '',
      phone: '',
      email: '',
      nuj_number: '',
      fmv_name: '',
      fmv_phone: '',
      workOptions: ['betonacél szerelés', 'beton szállítás', 'betonacél szállítás', 'földmunka', 'komplett szerkezetépítés', 'kőműves munkák', 'szerkezetépítés', 'vízszigetelés', 'zsalu bontás és takarítás', 'gépészet'],
      fmv_role: '',
      fmv_email: '',
      fmv_number: '',
      company_types: [
        { value: 'Egyéni vállalkozó', text: 'Egyéni vállalkozó' },
        { value: 'Cég', text: 'Cég' },
      ],
      fmv_nuj_number: '',
      role_group: [],
      roleOptions: [
        {

          text: 'Ügyvezető',
          value: 'Ügyvezető',
        },
        {
          text: 'E-naplóért felelős',
          value: 'E-naplóért felelős',
        },
        {
          text: 'Kapcsolattartó',
          value: 'Kapcsolattartó',
        },
      ],
      work: '',
      role: '',
      limit: 10,
      selected: null,
    }
  },
  mounted() {
    this.loadSubcontractorData()
  },
  methods: {
    removeRoleGroup(index) {
      this.role_group.splice(index, 1)
    },
    addToRoleGroup() {
      if (this.role === '' || this.represented === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező!',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      }
      const role = []
      this.role.forEach(element => {
        role.push(element.value)
      })
      this.role_group.push({ represented: this.represented, roles: role })

      this.role = []
      this.represented = ''
    },
    loadSubcontractorData() {
      axios({
        method: 'get',
        
        url: `subcontractor/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.company_name = response.data.company_name
        this.company_location = response.data.company_location
        this.tax_number = response.data.tax_number
        this.company_number = response.data.company_number
        this.chamber_number = response.data.chamber_number
        this.bank_account_number = response.data.bank_account_number
        this.role_group = response.data.represented
        this.phone = response.data.phone
        this.email = response.data.email
        this.nuj_number = response.data.nuj_number
        this.fmv_name = response.data.fmv_name
        this.fmv_phone = response.data.fmv_phone
        this.fmv_email = response.data.fmv_email
        this.fmv_number = response.data.fmv_number
        this.fmv_nuj_number = response.data.fmv_nuj_number
        this.work = response.data.work
        this.fmv_role = response.data.fmv_role
        this.role = response.data.role
        this.company_type = response.data.company_type
      }).catch(error => {
        this.$store.dispatch('showError', error.response.data.message)
      }).finally(() => {
        this.$store.dispatch('hideLoader')
      })
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Kérés elküldve feldolgozásra',
          icon: 'LoaderIcon',
          variant: 'warning',
        },
      })
      axios({
        method: 'put',
        
        url: `subcontractor/update/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          company_name: this.company_name,
          company_location: this.company_location,
          tax_number: this.tax_number,
          company_number: this.company_number,
          chamber_number: this.chamber_number,
          bank_account_number: this.bank_account_number,
          represented: this.role_group,
          phone: this.phone,
          email: this.email,
          nuj_number: this.nuj_number,
          fmv_name: this.fmv_name,
          fmv_phone: this.fmv_phone,
          fmv_email: this.fmv_email,
          fmv_number: this.fmv_number,
          fmv_nuj_number: this.fmv_nuj_number,
          work: this.work,
          fmv_role: this.fmv_role,
          company_type: this.company_type,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen frissítve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'monolit.subcontractor.list' })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
  .flatpickr-input{
    display: none !important;
  }
</style>

<style>
  .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after{
    content: "►" !important;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before{
    content: "◄" !important;
  }
  .autosuggest__results-container{
    background: var(--primary);
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .autosuggest__results-item{
    list-style: square;
    margin-top: 3px;
  }
</style>
